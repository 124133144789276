import {
  ICreatePrescriptionPayload,
  ICreatePrescriptionResponse,
  IMedicineTestResponse,
  IPrescriptionConfigResponse,
} from "../../../models/ApiRequestResponse/doctor";
import { IPrescriptionFilterType } from "../../../models/doctor";

import { GBSCError } from "../../../models/GBSCError";

import { get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const prescriptionConfigApi = async (
  filterType: IPrescriptionFilterType
): Promise<IPrescriptionConfigResponse | GBSCError> => {
  try {
    const response = await get<IPrescriptionConfigResponse>(
      `${APIEndpoint.doctor.prescriptionConfig}?filter=${filterType}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createPrescriptionApi = async (
  requestBody: ICreatePrescriptionPayload
): Promise<ICreatePrescriptionResponse | GBSCError> => {
  try {
    const response = await post<ICreatePrescriptionResponse>(
      APIEndpoint.doctor.createPrescription,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const searchMedicineTestApi = async (
  type: string,
  searchVal: string
): Promise<IMedicineTestResponse | GBSCError> => {
  try {
    const response = await get<IMedicineTestResponse>(
      `${APIEndpoint.doctor.searchMedicineTest}${type}?keyword=${searchVal}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
