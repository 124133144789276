import {
  IDoctorReviewRatingResponse,
  IReviewReplyRequest,
  IReviewReplyResponse,
} from "../../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { get, post } from "../../TypedApi";
import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDoctorRatingReviewListApi = async (
  doctorId?: string,
  noOfStars?: string,
  repliedByDoctor?: string,
  keyword?: string
): Promise<IDoctorReviewRatingResponse | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.doctorReviewList;
    url += doctorId ? `&doctor=${doctorId}` : "";
    
    const queryParam =
      noOfStars || repliedByDoctor || keyword
        ? `${
            noOfStars
              ? `noOfStars=${noOfStars}`
              : repliedByDoctor
              ? `repliedByDoctor=${repliedByDoctor}`
              : `keyword=${keyword}`
          }`
        : "";

    const finalUrl = queryParam ? `${url}&${queryParam}` : url;

    const response = await get<IDoctorReviewRatingResponse>(finalUrl);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const postReplyToPatientReviewApi = async (
  requestBody: IReviewReplyRequest
): Promise<IReviewReplyResponse | GBSCError> => {
  try {
    const url = APIEndpoint.doctor.reviewReply;
    const response = await post<IReviewReplyResponse>(url, requestBody);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
