import { lazy } from "react";

const GetStarted = lazy(
  () => import("../../views/onboarding/GetStarted/GetStarted")
);
const LanguageSelection = lazy(
  () => import("../../views/onboarding/LanguageSelection/LanguageSelection")
);
const MobileVerify = lazy(
  () => import("../../views/onboarding/MobileOTPVerify/MobileVerify")
);
const OTPVerify = lazy(
  () => import("../../views/onboarding/MobileOTPVerify/OTPVerify")
);
const LocationAccess = lazy(
  () => import("../../views/onboarding/LocationAccess/LocationAccess")
);

const OnboardingRoutes = [
  {
    path: "/",
    component: <LanguageSelection />,
  },
  {
    path: "/get-started",
    component: <GetStarted />,
  },
  { path: "/mobileno", component: <MobileVerify /> },
  { path: "/otp", component: <OTPVerify /> },
  { path: "/location-access", component: <LocationAccess /> },
];

export default OnboardingRoutes;
