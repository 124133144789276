import React from "react";
import { Dialog as DialogMUI, styled } from "@mui/material";

const CustomDialog = styled(DialogMUI)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    textAlign: "center",
    width: "584px",
    backgroundColor:"#F2F6F7"
  },
}));

interface IProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeHandler?: () => void;
}
const Dialog: React.FC<IProps> = (props) => {
  const { children, isOpen = true, closeHandler } = props;

  return (
    <CustomDialog open={isOpen} onClose={closeHandler}>
      {children}
    </CustomDialog>
  );
};

export default Dialog;
