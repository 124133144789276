import React, { Suspense, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Router from "./routes/Router";

import "./App.css";

import Loader from "./components/Loader/Loader";

import { IAPIStatusCode } from "./utility/apis/APIEndpointConfig";
import { getAccessTokenApi } from "./utility/apis/onboarding/OnBoardingApis";
import { storage } from "./utility/Storage";

const App: React.FC = () => {
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const { status } = error.response;
        if (status === IAPIStatusCode.UNAUTHENTICATED) {
          if (!storage.getSessionToken("lang")) {
            navigate("/");
            return;
          }
          navigate("/mobileNo");
        }
      }
      return Promise.reject(error);
    }
  );

  const getAccessTokenDetail = async () => {
    const getAccessTokenRes = await getAccessTokenApi();
    if (
      getAccessTokenRes.statusCode === IAPIStatusCode.SUCCESS &&
      "result" in getAccessTokenRes
    ) {
      storage.setSessionToken("lang", getAccessTokenRes?.result?.language);
      storage.setSessionToken("user_id", getAccessTokenRes?.result?.user_id);
      storage.setSessionToken("roleType", getAccessTokenRes?.result?.role);
    }
  };

  useEffect(() => {
    getAccessTokenDetail();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      <Router />
    </Suspense>
  );
};

export default App;
