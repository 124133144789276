import { lazy } from "react";

const ScheduleListing = lazy(
  () => import("../../views/doctor/Schedule/Listing/Listing")
);
const AvailabilityListing = lazy(
  () => import("../../views/doctor/AvailabilitySettings/Listing/Listing")
);
const CreateEditAvailability = lazy(
  () =>
    import(
      "../../views/doctor/AvailabilitySettings/CreateEditAvailability/CreateEditAvailability"
    )
);
const DoctorProfile = lazy(() => import("../../views/doctor/Profile/Profile"));
const DoctorProfileDetail = lazy(
  () => import("../../views/common/DoctorProfile/DoctorProfile")
);
const MedicalRecords = lazy(
  () => import("../../views/doctor/MedicalRecords/MedicalRecords")
);
const CreatePrescription = lazy(
  () => import("../../views/doctor/Prescription/CreatePrescription")
);

const IpDash = () => {
  return <h1>IpDash</h1>;
};

const Chat = () => {
  return <h1>Chat</h1>;
};

const DoctorRoutes = [
  { path: "/schedule", component: <ScheduleListing /> },
  { path: "/ip-dash", component: <IpDash /> },
  { path: "/chats", component: <Chat /> },
  { path: "/profile", component: <DoctorProfile /> },
  { path: "/profile-detail", component: <DoctorProfileDetail /> },
  { path: "/availability-settings", component: <AvailabilityListing /> },
  { path: "/availability-create", component: <CreateEditAvailability /> },
  { path: "/medical-records", component: <MedicalRecords /> },
  { path: "/create-prescription", component: <CreatePrescription /> },
];

export default DoctorRoutes;
