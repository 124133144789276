import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GBSCError } from "../../../models/GBSCError";

import {
  IMemeberList,
  IMemberListDetail,
} from "../../../models/ApiRequestResponse/patient";

import { getMemberListApi } from "../../../utility/apis/patient/profile";

export const fetchPatientMembers = createAsyncThunk<IMemeberList | GBSCError>(
  "patient/fetchPatientMember",
  async () => {
    const response = await getMemberListApi();
    return response;
  }
);

const fetchPatientMembersSlice = createSlice({
  name: "fetchPatientMember",
  initialState: {
    list: [] as IMemberListDetail[],
    isLoading: true as boolean,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPatientMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = action.payload.result;
        }
      })
      .addCase(fetchPatientMembers.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default fetchPatientMembersSlice.reducer;
