import {
  IClinicListResponse,
  IDeleteAvailabilitySetting,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IScheduleAvailabilitySetting,
  IScheduleCreateResponse,
  IDoctorScheduleListing,
} from "../../../models/ApiRequestResponse/doctor";

import { GBSCError } from "../../../models/GBSCError";

import { deleteMethod, get, patch, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getAvailabilitySettingListingApi = async (): Promise<
  IDoctorScheduleListing | GBSCError
> => {
  try {
    const response = await get<IDoctorScheduleListing>(
      APIEndpoint.doctor.availabilitySetting
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createAvailabilitySettingApi = async (
  requestBody: IScheduleAvailabilitySetting
): Promise<IScheduleCreateResponse | GBSCError> => {
  try {
    const response = await post<IScheduleCreateResponse>(
      APIEndpoint.doctor.availabilitySetting,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updateAvailabilitySettingApi = async (
  requestBody: IScheduleAvailabilitySetting,
  scheduleId: string
): Promise<IScheduleCreateResponse | GBSCError> => {
  try {
    const response = await patch<IScheduleCreateResponse>(
      `${APIEndpoint.doctor.availabilitySetting}/${scheduleId}`,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getClinicListApi = async (): Promise<
  IClinicListResponse | GBSCError
> => {
  try {
    const response = await get<IClinicListResponse>(
      APIEndpoint.doctor.clinicList
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const deleteAvailabilitySettingApi = async (
  availabilitySettingId: string
): Promise<IDeleteAvailabilitySetting | GBSCError> => {
  try {
    const response = await deleteMethod<IDeleteAvailabilitySetting>(
      `${APIEndpoint.doctor.availabilitySetting}/${availabilitySettingId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
