import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAvailabilitySettingListingApi } from "../../../utility/apis/doctor/availabilitySetting";

import {
  IScheduleAvailabilitySetting,
  IDoctorScheduleListing,
} from "../../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../../models/GBSCError";

export const fetchAvailabilitySettings = createAsyncThunk<
  IDoctorScheduleListing | GBSCError
>("doctor/availabilitySettings", async () => {
  const response = await getAvailabilitySettingListingApi();
  return response;
});

const availabilitySettingSlice = createSlice({
  name: "availabilitySetting",
  initialState: {
    list: [] as IScheduleAvailabilitySetting[],
    detail: null as IScheduleAvailabilitySetting | null,
    isLoading: true as boolean,
  },
  reducers: {
    detail: (state, action) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailabilitySettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAvailabilitySettings.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = action.payload.result;
        }
      })
      .addCase(fetchAvailabilitySettings.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { detail } = availabilitySettingSlice.actions;
export default availabilitySettingSlice.reducer;
