import { storage } from "./Storage";

export const generateBasicAuthHeader = (username: string, password: string) => {
    if (storage.getSessionToken("BasicAuth")) {
        return storage.getSessionToken("BasicAuth")
    } else {
        const token = btoa(`${username}:${password}`);
        const tokenInfo = `Basic ${token}`;
        storage.setSessionToken("BasicAuth", tokenInfo)
        return tokenInfo;
    }
};
