import { lazy } from "react";

const FindDoctor = lazy(
  () => import("../../views/doctor/FindDoctor/FindDoctor")
);

const ProfilePatient = lazy(
  () => import("../../views/patient/Profile/Profile")
);

const Checkout = lazy(() => import("../../views/patient/Checkout/Checkout"));

const DoctorProfile = lazy(
  () => import("../../views/common/DoctorProfile/DoctorProfile")
);

const Home = () => {
  return <h1>Home</h1>;
};

const Medicines = () => {
  return <h1>Medicines</h1>;
};

const TestReports = () => {
  return <h1>TestReports</h1>;
};

const HealthTips = () => {
  return <h1>HealthTips</h1>;
};

const PatientRoutes = [
  { path: "/home", component: <Home /> },
  { path: "/find-doctor", component: <FindDoctor /> },
  { path: "/medicines", component: <Medicines /> },
  { path: "/test-reports", component: <TestReports /> },
  { path: "/health-tips", component: <HealthTips /> },
  { path: "/profile-patient", component: <ProfilePatient /> },
  { path: "/checkout", component: <Checkout /> },
  { path: "/doctor-profile/:doctorId", component: <DoctorProfile /> },
];

export default PatientRoutes;
