
class Storage {
    public getSessionToken(key: string): string | undefined {
        const sessionDataString = localStorage.getItem(key);
        if (sessionDataString !== null && sessionDataString.length > 0) {
            return sessionDataString;
        }
    }

    public setSessionToken(key: string, session: any) {
        localStorage.setItem(key, session);
    }

    public removeSessionData() {
        localStorage.removeItem("sessionData");
    }

    public isUserLoggedIn() {
        return localStorage.getItem("sessionData") ? true : false;
    }
}

export const storage = new Storage();
