import {
  IDoctorReviewRequest,
  IDoctorReviewResponse,
  IReserveAppointmentPatientRequest,
  IReserveAppointmentResponse,
  ISpecialitiesListResponse,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";
import { IFindDoctorList } from "../../../models/ApiRequestResponse/patient";

import { get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDoctorsSpecialitiesListApi = async (): Promise<
  ISpecialitiesListResponse | GBSCError
> => {
  try {
    const response = await get<ISpecialitiesListResponse>(
      APIEndpoint.patient.specialitiesList
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getFindDoctorListApi = async (
  params: string
): Promise<IFindDoctorList | GBSCError> => {
  try {
    const response = await get<IFindDoctorList>(
      `${APIEndpoint.patient.findDoctor}${params}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const reserveAppointmentFromPatient = async (
  requestBody: IReserveAppointmentPatientRequest
): Promise<IReserveAppointmentResponse | GBSCError> => {
  try {
    const response = await post<IReserveAppointmentResponse>(
      APIEndpoint.patient.bookingAppointment,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const postReviewToDoctorApi = async (
  requestBody: IDoctorReviewRequest
): Promise<IDoctorReviewResponse | GBSCError> => {
  try {
    const response = await post<IDoctorReviewResponse>(
      APIEndpoint.patient.postReview,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
