export const APIEndpoint = {
  onBoarding: {
    login: "user-service/v1/user/login",
    otpVerify: "user-service/v1/user/verify-otp",
  },
  patient: {
    profile: `user-service/v1/user/patient-profile`,
    updateMobileChangeOtp: `user-service/v1/user/patient-phone-otp`,
    patientMember: "user-service/v1/patient-member",
    doctorProfile: "doctor-service/v1/doctor/doctor-profile",
    specialitiesList: "doctor-service/v1/doctor/specialities",
    findDoctor: "doctor-service/v1/doctor/doctors?limit=100&offset=0",
    bookingAppointment: "booking-service/v1/booking-appointment",
    postReview: "booking-service/v1/review",
  },
  doctor: {
    config: `doctor-service/v1/config`,
    availabilitySetting: `doctor-service/v1/schedule-settings`,
    clinicList: `doctor-service/v1/doctor/clinic`,
    scheduleList: `doctor-service/v1/schedule`,
    scheduleSlots: `doctor-service/v1/schedule-slot`,
    checkNumber: "user-service/v1/user/search-patient",
    sendOtpPatientCreation: "user-service/v1/user/create-patient-otp",
    createPatient: "user-service/v1/user/create-patient",
    cancelScheduleAppointment:
      "booking-service/v1/booking-appointment/bulk-delete",
    doctorReviewList: "booking-service/v1/review?page=1&limit=10",
    reviewReply: "booking-service/v1/review/reply",
    reschedule: "booking-service/v1/reschedule",
    prescriptionConfig: "booking-service/v1/config",
    createPrescription: "booking-service/v1/prescription",
    searchMedicineTest: "booking-service/v1/",
  },
  accessToken: "user-service/v1/user/access-token",
};

export enum IAPIStatusType {
  SUCCESS = "success",
  ERROR = "error",
}
export enum IAPIStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHENTICATED = 401,
  SUCCESS = 200,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
}

export const messages = {
  somethingWentWrong: "Something went wrong",
};
