import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IAppointments,
  ICheckNumberAppointmentDetail,
  ICreatePatientVerifyOtpRequest,
  IDoctorScheduleSlotDetail,
} from "../../../models/ApiRequestResponse/doctor";
import {
  ICheckNumberErrors,
  IMakeAppointmentFromDoctorSlice,
} from "../../../models/doctor";

import { getDoctorScheduleSlotApi } from "../../../utility/apis/doctor/appointment";
import { countryCode } from "../../../utility/text";
import {
  checkNumberAppointmentApi,
  createPatientVerifyOtpApi,
} from "../../../utility/apis/doctor/appointment";
import { INotifyEnum, notify } from "../../../utility/toaster";

export const fetchScheduleSlots = createAsyncThunk(
  "doctor/scheduleSlots",
  async (doctorId?: string) => {
    const response = await getDoctorScheduleSlotApi(doctorId);
    return response;
  }
);

export const checkNumberAppointment = createAsyncThunk(
  "doctor/makePatientAppointment",
  async (mobileNo: string) => {
    const response = await checkNumberAppointmentApi({
      countryCode,
      mobileNo,
    });
    return response;
  }
);

export const createPatientVerifyOTP = createAsyncThunk(
  "doctor/createPatientVerifyOTP",
  async (requestPayload: ICreatePatientVerifyOtpRequest) => {
    const response = await createPatientVerifyOtpApi(requestPayload);
    return response;
  }
);

const appointmentSlice = createSlice({
  name: "scheduleSlots",
  initialState: {
    slot: {} as IDoctorScheduleSlotDetail,
    makePatientAppointment: {} as IMakeAppointmentFromDoctorSlice,
    checkNumberError: {
      message: "",
      statusCode: "",
    } as ICheckNumberErrors,
    fechedPatientDetail: {} as ICheckNumberAppointmentDetail,
    isLoading: true as boolean,
    appointmentDetail: {} as IAppointments,
  },
  reducers: {
    makePatientAppointment: (state, action) => {
      state.makePatientAppointment = action.payload;
    },
    resetMakeAnAppointment: (state) => {
      state.checkNumberError = {} as ICheckNumberErrors;
      state.fechedPatientDetail = {} as ICheckNumberAppointmentDetail;
    },
    setAppointmentDetail: (state, action) => {
      state.appointmentDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // doctor slots
      .addCase(fetchScheduleSlots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchScheduleSlots.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.slot = action.payload.result;
        }
      })
      .addCase(fetchScheduleSlots.rejected, (state) => {
        state.isLoading = false;
      })
      // fetch patient profile if patient exist
      .addCase(checkNumberAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkNumberAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.fechedPatientDetail = action.payload.result;
        }
        state.checkNumberError.message = action.payload.message;
        state.checkNumberError.statusCode = action.payload.statusCode;
      })
      .addCase(checkNumberAppointment.rejected, (state) => {
        state.isLoading = false;
      })
      // create patient if not exist
      .addCase(createPatientVerifyOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPatientVerifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          notify(INotifyEnum.SUCCESS, action.payload?.message);
          state.fechedPatientDetail = action.payload.result;
        }
      })
      .addCase(createPatientVerifyOTP.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  makePatientAppointment,
  resetMakeAnAppointment,
  setAppointmentDetail,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
