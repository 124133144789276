import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProfileOpen: false as boolean,
  isReviewOpen: false as boolean,
  isAvailabilityOpen: false as boolean,
  isClinicTeamOpen: false as boolean,
  isTemplatesOpen: false as boolean,
};
const topNavigationSlice = createSlice({
  name: "topNavDoctor",
  initialState: {
    doctorProfile: initialState,
  },
  reducers: {
    setDoctorProfileDropdown: (state: any, action) => {
      state.doctorProfile = {
        ...state.doctorProfile,
        [action.payload]: !state.doctorProfile[action.payload],
      };
    },
  },
});

export const { setDoctorProfileDropdown } = topNavigationSlice.actions;
export default topNavigationSlice.reducer;
