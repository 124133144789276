import React from "react";
import Modal from "../../../components/Modal/Modal";
import { Grid, Typography } from "@mui/material";

import GetImages from "../../../components/GetImages/GetImages";

import style from "./DoctorProfile.module.css";

import { IClinic } from "../../../models/ApiRequestResponse/patient";
import { openClinicMapLocation } from "../../../utility";

interface IProps {
  isOpen: boolean;
  closeModalHandler: () => void;
  clinics: IClinic[];
}
const ViewDoctorClinic: React.FC<IProps> = (props) => {
  const { isOpen, closeModalHandler, clinics } = props;

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeModalHandler}
      backgroundColor="white"
      lineBottomBackground="white"
    >
      {" "}
      <Grid item className={style.viewMoreClinicContainer}>
        <Typography component={"h4"}>Select a clinic</Typography>
        {clinics.map((clinic, index) => (
          <Grid item className={style.clinicInfoWrapper} key={clinic.id}>
            <Grid item className={style.clinicDetailContainer}>
              <Grid item>
                <Typography component={"h6"} className={style.clinicName}>
                  {clinic.name}
                </Typography>
                <Typography component={"p"} className={style.priceBranch}>
                  ₹{clinic.consultation_fee} • {clinic.address}
                </Typography>
              </Grid>
              <Grid
                item
                className={style.directionIcon}
                onClick={() =>
                  openClinicMapLocation(clinic.latitude, clinic.longitude)
                }
              >
                <GetImages
                  name="LocationAccessSmallIcon"
                  width="20"
                  height="20"
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

export default ViewDoctorClinic;
