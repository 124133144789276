import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import OnboardingRoutes from "./onboarding";
import PatientRoutes from "./patient";
import DoctorRoutes from "./doctor";

import TopNavigation from "../components/Navigation/MobileNavigation/TopNavigation/TopNavigation";
import BottomNavigation from "../components/Navigation/MobileNavigation/BottomNavigation/BottomNavigation";
import WebTopNavigation from "../components/Navigation/WebNavigation/TopNavigation";

import { storage } from "../utility/Storage";

import { IRoleType } from "../models/role";
const onboardingRoutes = [
  "/",
  "/get-started",
  "/mobileno",
  "/otp",
  "/location-access",
];
const hideTopNav = ["/profile"];
const patientRoutes = [
  "/home",
  "/find-doctor",
  "/medicines",
  "/test-reports",
  "/health-tips",
];

const doctorRoutes = [
  "/availability-settings",
  "/availability-create",
  "/schedule",
  "/ip-dash",
  "/chats",
  "/profile",
];
const doctorBottomNavRoutes = ["/schedule", "/ip-dash", "/chats", "/profile"];
const Router: React.FC = () => {
  const location = useLocation();
  const [isWeb, setIsWeb] = useState(window.innerWidth > 768);

  const currentPath = location.pathname;
  const isOnboarding = onboardingRoutes.includes(currentPath);
  const isPatientRoute = patientRoutes.includes(currentPath);
  const isDoctorRoute = doctorRoutes.includes(currentPath);
  const isHideTopNav = hideTopNav.includes(currentPath);
  const showBottomNavForDoctor = doctorBottomNavRoutes.includes(currentPath);
  const roleType = storage.getSessionToken("roleType");

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth > 768, "window.innerWidth > 768");

      setIsWeb(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isOnboarding && !isHideTopNav && (
        <>
          {isPatientRoute && <><TopNavigation /><BottomNavigation/></>}
          {isDoctorRoute && !isWeb && <TopNavigation />}
        </>
      )}
      {roleType === IRoleType.DOCTOR && isWeb && <WebTopNavigation />}
      {showBottomNavForDoctor && !isWeb ? <BottomNavigation /> : <></>}
      <Routes>
        {/* Onboarding, Patient, Doctor */}
        {[...OnboardingRoutes, ...PatientRoutes, ...DoctorRoutes].map(
          (route, index) => (
            <Route path={route.path} element={route.component} key={index} />
          )
        )}
      </Routes>
    </>
  );
};

export default Router;
