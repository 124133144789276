import {
  ICancelScheduleAppointmentsRequest,
  ICancelScheduleAppointmentsResponse,
  ICheckNumberAppointment,
  ICreatePatientVerifyOtpRequest,
  ICreatePatientVerifyOtpResponse,
  IDoctorScheduleSlots,
  IRescheduleAppointmentRequest,
  IRescheduleAppointmentResponse,
  IScheduleListing,
  ISendOTPParams,
  ISendOtpPatientCreationRequest,
  ISendOtpPatientCreationResponse,
} from "../../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { deleteMethod, get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getScheduleAppointmentListingApi = async (): Promise<
  IScheduleListing | GBSCError
> => {
  try {
    const response = await get<IScheduleListing>(
      APIEndpoint.doctor.scheduleList
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorScheduleSlotApi = async (
  id?: string
): Promise<IDoctorScheduleSlots | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.scheduleSlots;
    const response = await get<IDoctorScheduleSlots>(id ? url + `/${id}` : url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const checkNumberAppointmentApi = async (
  params: ISendOTPParams
): Promise<ICheckNumberAppointment | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.checkNumber;
    const response = await get<ICheckNumberAppointment>(
      `${url}?phoneNumber=${params.mobileNo}&countryCode=${params.countryCode}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const sendOtpPatientCreationApi = async (
  requestBody: ISendOtpPatientCreationRequest
): Promise<ISendOtpPatientCreationResponse | GBSCError> => {
  try {
    const response = await post<ISendOtpPatientCreationResponse>(
      APIEndpoint.doctor.sendOtpPatientCreation,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createPatientVerifyOtpApi = async (
  requestBody: ICreatePatientVerifyOtpRequest
): Promise<ICreatePatientVerifyOtpResponse | GBSCError> => {
  try {
    const response = await post<ICreatePatientVerifyOtpResponse>(
      APIEndpoint.doctor.createPatient,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const cancelScheduleAppointmentsApi = async (
  requestBody: ICancelScheduleAppointmentsRequest
): Promise<ICancelScheduleAppointmentsResponse | GBSCError> => {
  try {
    const response = await deleteMethod<ICancelScheduleAppointmentsResponse>(
      APIEndpoint.doctor.cancelScheduleAppointment,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const rescheduleCancelledAppointmentsApi = async (
  requestBody: IRescheduleAppointmentRequest
): Promise<IRescheduleAppointmentResponse | GBSCError> => {
  try {
    const response = await post<IRescheduleAppointmentResponse>(
      APIEndpoint.doctor.reschedule,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
