import { combineReducers } from "redux";

import availabilitySettingReducer from "./doctor/availabilitySettingsSlice";
import appointmentReducer from "./doctor/appointmentSlice";
import scheduleReducer from "./doctor/scheduleSlice";
import doctorProfileReducer from "./doctor/profileSlice";
import topNavigationReducer from "./doctor/topNavigationSlice";
import prescriptionReducer from "./doctor/prescriptionSlice";

import memberReducer from "./patient/memberSlice";
import findDoctorReducer from "./patient/findDoctorSlice";
import configReducer from "./configSlice";

const rootReducer = combineReducers({
  availability: availabilitySettingReducer,
  member: memberReducer,
  appointment: appointmentReducer,
  schedule: scheduleReducer,
  config: configReducer,
  findDoctor: findDoctorReducer,
  doctorProfile: doctorProfileReducer,
  topNavigation: topNavigationReducer,
  prescription: prescriptionReducer,
});

export default rootReducer;
