export enum IButtonVariant {
  GREY,
  WHITE,
  ORANGE,
  ORANGE_ROUNDED,
  TEXT,
}
export enum IButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
}

export enum IChipVariant {
  ROUNDED = "ROUNDED",
  DEFAULT = "",
}
