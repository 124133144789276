import dayjs from "dayjs";

import { IDays } from "../models/doctor";
import { ILanguageEnums } from "../models/onboarding";
import { IQualificationDetail } from "../models/ApiRequestResponse/patient";
import { IScheduleAvailabilitySetting } from "../models/ApiRequestResponse/doctor";

import { mapUrl } from "./Config";

const dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const tranformIndexesToDays = (arr: string[]) => {
  let isComplete = false,
    result = [];
  for (let i = 0; i < arr.length; i++) {
    isComplete = +arr[i] - 1 === i;
    result.push(dayList[+arr[i] - 1]);
  }
  return { isComplete, list: result };
};

const tranformDaysToIndexes = (dayIndexesArr: string[]) => {
  return dayIndexesArr.map((dayIndex, index) =>
    dayList[+dayIndex - 1].toString()
  );
};

const convertDaysToRanges = (arr: string[]) => {
  const { isComplete, list } = tranformIndexesToDays(arr);

  if (isComplete) {
    if (list.length < 3) {
      if (list.length === 1) {
        return list[0];
      }
      return `${list[0]},${list[1]}`;
    } else {
      return `${list[0]}-${list[list.length - 1]}`;
    }
  } else {
    return `${list.join(",")}`;
  }
};

const timeInAmPmFormat = (value: string) => {
  return dayjs(`${dayjs().format("YYYY-MM-DD")}T${value}`).format("hh:mm a");
};

const transformClasses = function (...args: any) {
  return args.join(" ");
};

const getAvailabilityListByDay = (
  list: IScheduleAvailabilitySetting[],
  day: IDays
) => {
  const dayIndexInList = dayList.indexOf(day) + 1;
  return list.filter((item, index) =>
    item.days.includes(dayIndexInList.toString())
  );
};

const languagesList = [
  {
    label: "English",
    value: ILanguageEnums.ENG,
  },
  {
    label: "हिंदी",
    value: ILanguageEnums.HIN,
  },
  {
    label: "ગુજરાતી",
    value: ILanguageEnums.GUJ,
  },
  {
    label: "বাংলা",
    value: ILanguageEnums.BEN,
  },
];

const doctorSpeakLanguages = (langList: string[]) => {
  if (langList.length === 0) return "";
  const langRes: string[] = [];
  languagesList.forEach((list, index) => {
    langList.forEach((lis, ind) => {
      if (list.value.includes(lis)) {
        langRes.push(list.label);
      }
    });
  });
  return langRes.join(" • ");
};

const doctorDegreeName = (qual: IQualificationDetail[]) => {
  return qual.map((list, index) => list.degree_name).join(",");
};

const getDateTime = (value: string) => {
  const now = dayjs(value);
  if (now.isSame(dayjs(), "day")) {
    return "Today";
  } else if (now.isSame(dayjs().add(1, "day"), "day")) {
    return "Tomorrow";
  } else {
    return now.format("MMM D, YYYY h:mm a");
  }
};

const getNextSixDates = () => {
  const dates = [];
  const today = dayjs();
  for (let i = 0; i <= 6; i++) {
    const nextDate = today.clone().add(i, "days");
    dates.push({
      date: nextDate.format("DD MMM"),
      day: i === 0 ? "Today" : nextDate.format("ddd"),
    });
  }
  return dates;
};

const listingInfo = [
  {
    label: "Speciality",
    value: "speciality",
    isSelection: true,
  },
  {
    label: "In-Clinic",
    value: "In-Clinic",
    isSelection: false,
  },
  {
    label: "Video Call",
    value: "Video Call",
    isSelection: false,
  },
  {
    label: "Experince",
    value: "experience",
    isSelection: true,
  },
  {
    label: "Distance",
    value: "distance",
    isSelection: true,
  },
  {
    label: "Language",
    value: "language",
    isSelection: true,
  },
];

const openClinicMapLocation = (lat: number, lng: number) => {
  const url = `${mapUrl}${lat},${lng}`;
  window.open(url, "_blank");
};

const transformDateToMMDDYYY = (date: any) => {
  return dayjs(date).format("MMM D, YYYY");
};

export {
  transformDateToMMDDYYY,
  openClinicMapLocation,
  listingInfo,
  getNextSixDates,
  getDateTime,
  languagesList,
  transformClasses,
  tranformIndexesToDays,
  convertDaysToRanges,
  timeInAmPmFormat,
  tranformDaysToIndexes,
  getAvailabilityListByDay,
  doctorSpeakLanguages,
  doctorDegreeName,
};
