import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IPrescription } from "../../../models/ApiRequestResponse/doctor";
import {
  IAccordionInputChipListEnums,
  ICreatePrescription,
  IPrescriptionFilterType,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import { prescriptionConfigApi } from "../../../utility/apis/doctor/prescription";

export const fetchPrescriptionConfig = createAsyncThunk(
  "config/prescription",
  async () => {
    const response = await prescriptionConfigApi(
      IPrescriptionFilterType.PRESCRIPTION
    );
    return response;
  }
);

const prescriptionSlice = createSlice({
  name: "prescription",
  initialState: {
    config: {} as IPrescription,
    isLoading: true as boolean,
    create: {} as ICreatePrescription,
  },
  reducers: {
    setVitals: (state: any, action) => {
      state.create[action.payload.identifier] = action.payload.value;
    },
    setPrescriptionValues: (state: any, action) => {
      const isDietOrNote =
        action.payload.identifier ===
          ITitleInputValuesListEnums.DIET_AND_EXERCISE ||
        action.payload.identifier === ITitleInputValuesListEnums.NOTE;
      const isHealthCategories =
        action.payload.identifier ===
        IAccordionInputChipListEnums.HEALTH_CATEGORIES;
      const isNextVisit =
        action.payload.identifier === IAccordionInputChipListEnums.NEXT_VISIT;
      if (isDietOrNote) {
        state.create[action.payload.identifier] = action.payload.value;
        return;
      } else if (isHealthCategories) {
        state.create.healthCategoryId = action.payload.value;
        return;
      } else if (isNextVisit) {
        const [nextVisitType, nextVisitNo] = action.payload.value;
        state.create.nextVisitType = nextVisitType;
        state.create.nextVisitNo = +nextVisitNo;
        return;
      } else {
        state.create[action.payload.identifier] = [
          ...(state?.create[action.payload.identifier] || []),
          ...action.payload.value,
        ];
      }
    },
    removePrescriptionItem: (state: any, action) => {
      state.create[action.payload.identifier] = state.create[
        action.payload.identifier
      ].filter(
        (item: any, index: number) =>
          (item.name ?? item) !== action.payload.value &&
          index !== action.payload.itemIndex
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrescriptionConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPrescriptionConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.config = action.payload.result.prescription;
        }
      })
      .addCase(fetchPrescriptionConfig.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setVitals, setPrescriptionValues, removePrescriptionItem } =
  prescriptionSlice.actions;
export default prescriptionSlice.reducer;
