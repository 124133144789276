export class GBSCError {
  statusCode: number;
  error: string;
  message: string;

  constructor(error: string, message: string, statusCode: number) {
    this.statusCode = statusCode;
    this.message = message;
    this.error = error;
  }
}
