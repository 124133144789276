import { IConfigResponse } from "../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../models/GBSCError";
import { IFilterType } from "../../models/patient";

import { get } from "../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "./APIEndpointConfig";

export const getConfigDoctorPatientApi = async (
  filterKey?: IFilterType
): Promise<IConfigResponse | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.config;
    const response = await get<IConfigResponse>(
      filterKey ? `${url}?filter=${filterKey}` : url
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
