import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IDoctorProfleDetail } from "../../../models/ApiRequestResponse/patient";
import { IDoctorReviewRatingDetail } from "../../../models/ApiRequestResponse/doctor";

import { getDoctorProfileByIdApi } from "../../../utility/apis/patient/profile";
import { getDoctorRatingReviewListApi } from "../../../utility/apis/doctor/profile";

interface IFectchDoctorReviewParams {
  doctorId?: string;
  noOfStars?: string;
  repliedByDoctor?: string;
  keyword?: string;
}
export const fetchDoctorProfile = createAsyncThunk(
  "doctor/profile",
  async (id?: string) => {
    const response = await getDoctorProfileByIdApi(id);
    return response;
  }
);

export const fetchDoctorReviewRatingList = createAsyncThunk(
  "doctor/reviewRatingList",
  async ({
    doctorId,
    noOfStars,
    repliedByDoctor,
    keyword,
  }: IFectchDoctorReviewParams) => {
    const response = await getDoctorRatingReviewListApi(
      doctorId,
      noOfStars,
      repliedByDoctor,
      keyword
    );
    return response;
  }
);

const doctorProfileSlice = createSlice({
  name: "doctorProfile",
  initialState: {
    detail: null as IDoctorProfleDetail | null,
    ratingReviewList: {} as IDoctorReviewRatingDetail,
    isLoading: true as boolean,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // doctor profile
      .addCase(fetchDoctorProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.detail = action.payload.result;
        }
      })
      .addCase(fetchDoctorProfile.rejected, (state) => {
        state.isLoading = false;
      })
      // Doctor review rating list
      .addCase(fetchDoctorReviewRatingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorReviewRatingList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.ratingReviewList = action.payload.result;
        }
      })
      .addCase(fetchDoctorReviewRatingList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default doctorProfileSlice.reducer;
