const slotBookedFromDoctor = "Patient Clinic visit is successful Booked";
const slotBookedFromPatient = "Your Clinic visit is successful Booked";
const slotBookVideoAppointmentDoctor =
  "Patient Video Consultation is Confirmed";
const slotBookVideoAppointmentPatient = "Your Video Consultation is Confirmed";

const countryCode = "+91";
export {
  countryCode,
  slotBookedFromDoctor,
  slotBookedFromPatient,
  slotBookVideoAppointmentDoctor,
  slotBookVideoAppointmentPatient,
};
