import React, { useState } from "react";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";

import GetImages from "../GetImages/GetImages";

import style from "./DoctorReviews.module.css";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { transformDateToMMDDYYY } from "../../utility";
import { storage } from "../../utility/Storage";
import { postReplyToPatientReviewApi } from "../../utility/apis/doctor/profile";
import { INotifyEnum, notify } from "../../utility/toaster";

import { IRoleType } from "../../models/role";

import { fetchDoctorReviewRatingList } from "../../store/slices/doctor/profileSlice";

const DoctorReviews: React.FC = () => {
  const [showReplySection, setShowReplySection] = useState<number | null>(null);

  const doctorProile = useAppSelector((state) => state.doctorProfile);
  const dispatch = useAppDispatch();

  const roleType = storage.getSessionToken("roleType");

  const replyToReviewHandler = async (event: any, reviewId: string) => {
    if (event.key === "Enter") {
      const postReplyToPatientReviewResponse =
        await postReplyToPatientReviewApi({
          reply: event.target.value,
          reviewId,
        });
      notify(INotifyEnum.SUCCESS, postReplyToPatientReviewResponse.message);
      setShowReplySection(null);
      dispatch(fetchDoctorReviewRatingList({}));
    }
  };
  return (
    <>
      {doctorProile?.ratingReviewList?.review?.length > 0 ? (
        doctorProile?.ratingReviewList?.review?.map((ratingList, index) => (
          <Grid
            item
            sx={{
              backgroundColor:
                roleType === IRoleType.DOCTOR && !ratingList.doctor_reply
                  ? "#FFF6EA"
                  : "#fff",
            }}
            className={style.container}
            key={`${ratingList.id}-${index}`}
          >
            <Grid item className={style.reviewerReplyHeader}>
              <Typography component={"h6"}>{ratingList.name}</Typography>
              <Typography component={"p"}>
                {transformDateToMMDDYYY(ratingList?.createdAt)}
              </Typography>
            </Grid>
            <Grid item className={style.reviewStarContainer}>
              {Array(ratingList?.no_of_stars).fill(
                <GetImages name="YellowStarSmallIcon" width="16" height="16" />
              )}
              {ratingList?.no_of_stars !== 5 &&
                Array(5 - ratingList?.no_of_stars).fill(
                  <GetImages
                    name="GreyedStarSmallIcon"
                    width="16"
                    height="16"
                  />
                )}
            </Grid>
            <Grid item>
              <Typography component={"p"} className={style.reviewerText}>
                {ratingList?.review_message}
              </Typography>
              {!ratingList?.doctor_reply && roleType === IRoleType.DOCTOR && (
                <>
                  {index !== showReplySection && (
                    <Typography
                      component={"p"}
                      className={style.replayToReview}
                      onClick={() => setShowReplySection(index)}
                    >
                      Reply
                    </Typography>
                  )}
                  {index === showReplySection && (
                    <Grid item className={style.replyContainer}>
                      <Grid item>
                        <Grid item className={style.reviewerReplyHeader}>
                          <Typography component={"h6"}>
                            {doctorProile?.detail?.name}
                          </Typography>
                          <Typography component={"p"}>
                            {transformDateToMMDDYYY(dayjs())}
                          </Typography>
                        </Grid>
                        <Typography
                          component={"p"}
                          className={style.repliedMessageContainer}
                        >
                          <input
                            type="text"
                            placeholder="Type here"
                            onKeyDown={(event) =>
                              replyToReviewHandler(event, ratingList?.id)
                            }
                            style={{ backgroundColor: "#FFF6EA" }}
                            autoFocus
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {ratingList?.doctor_reply && (
                <Grid item className={style.replyContainer}>
                  <Grid item>
                    <Grid item className={style.reviewerReplyHeader}>
                      <Typography component={"h6"}>
                        {doctorProile?.detail?.name}
                      </Typography>
                      <Typography component={"p"}>
                        {transformDateToMMDDYYY(ratingList?.updatedAt)}
                      </Typography>
                    </Grid>
                    <Typography
                      component={"p"}
                      className={style.repliedMessageContainer}
                    >
                      {ratingList?.doctor_reply}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography component={"h1"} textAlign={"center"} padding={"30px"}>
          No Patient reviews
        </Typography>
      )}
    </>
  );
};

export default DoctorReviews;
