import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
} from "@mui/material";

import style from "./Confirmation.module.css";

import Button from "../Button/Button";

import { IButtonVariant } from "../../models/button";

const CustomDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    padding: "16px 24px 24px",
    textAlign: "center",
  },
}));

interface IProps {
  title: string;
  name?: string;
  alterMessage: string;
  isOpen: boolean;
  actionHandler: () => void;
  closeHandler: () => void;
  takeActionLabel?: string;
  dontActionLabel?: string;
}
const Confirmation: React.FC<IProps> = (props) => {
  const {
    isOpen,
    alterMessage,
    name,
    title,
    actionHandler,
    closeHandler,
    takeActionLabel = "Yes",
    dontActionLabel = "No",
  } = props;

  return (
    <CustomDialog open={isOpen} sx={{ borderRadius: "12px!important" }}>
      <DialogTitle className={style.heading}>{title}</DialogTitle>
      <DialogContent className={style.content}>
        <Typography component={"p"}>
          {alterMessage}{" "}
          <Typography component={"span"}>{name ?? ""}</Typography> ?
        </Typography>
        <Grid item className={style.actionBtnsContainer}>
          <Grid item>
            <Button btnTrigger={actionHandler}>{takeActionLabel}</Button>
          </Grid>
          <Grid item className={style.closePopBtn}>
            <Button variant={IButtonVariant.WHITE} btnTrigger={closeHandler}>
              {dontActionLabel}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default Confirmation;
