import React from "react";
import { styled, TextField } from "@mui/material";

const InputField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFF",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFF",
      borderRadius: "8px",
      borderBottom: "1px solid #E0E0E0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFF",
      borderRadius: "8px",
      borderBottom: "1px solid #E0E0E0",
    },
    backgroundColor: "white",
    borderRadius: "8px",
  },
  "&.Mui-disabled": {
    "& .MuiOutlinedInput-root": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#8D8D8D",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#8D8D8D",
  },
}));

export { InputField };
