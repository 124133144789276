import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./Profile.module.css";

import Loader from "../../../components/Loader/Loader";
import DoctorReviews from "../../../components/DoctorReviews/DoctorReviews";
import GetImages from "../../../components/GetImages/GetImages";
import ChipSelect from "../../../components/ChipSelect/ChipSelect";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorProfile,
  fetchDoctorReviewRatingList,
} from "../../../store/slices/doctor/profileSlice";

import { doctorDegreeName } from "../../../utility";
import useDebounce from "../../../utility/hooks/useDebouncer";

import { IChipVariant } from "../../../models/button";

enum IRatingOptionEnum {
  ALL = "ALL",
  THREE_STAR = "3",
  FOUR_STAR = "4",
  NOT_REPLIED = "0",
}
const ratingFilterOptions = [
  {
    label: "All",
    value: IRatingOptionEnum.ALL,
  },
  {
    label: "< 3 Star",
    value: IRatingOptionEnum.THREE_STAR,
  },
  {
    label: "4+ Star",
    value: IRatingOptionEnum.FOUR_STAR,
  },
  {
    label: "Not Replied",
    value: IRatingOptionEnum.NOT_REPLIED,
  },
];

const Profile: React.FC = () => {
  const [ratingFilterSelectedOption, setRatingFilterSelectedOption] =
    useState<string>(ratingFilterOptions[0].value);
  const [isSearchReviewOn, setIsSearchReviewOn] = useState<boolean>(false);
  const [searchReview, setSearchReview] = useState<string>("");

  const debouncedDoctorSearch = useDebounce(searchReview, 1000);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;

  const viewDoctorProfileDetail = () => navigate("/profile-detail");

  useEffect(() => {
    if (!doctorProfile) {
      dispatch(fetchDoctorProfile());
    }
  }, []);

  useEffect(() => {
    if (
      ratingFilterSelectedOption === IRatingOptionEnum.THREE_STAR ||
      ratingFilterSelectedOption === IRatingOptionEnum.FOUR_STAR ||
      ratingFilterSelectedOption === IRatingOptionEnum.NOT_REPLIED ||
      ratingFilterSelectedOption === IRatingOptionEnum.ALL
    ) {
      dispatch(
        fetchDoctorReviewRatingList({
          ...(ratingFilterSelectedOption === IRatingOptionEnum.THREE_STAR ||
          ratingFilterSelectedOption === IRatingOptionEnum.FOUR_STAR
            ? {
                noOfStars: ratingFilterSelectedOption,
              }
            : {}),
          ...(ratingFilterSelectedOption === IRatingOptionEnum.NOT_REPLIED
            ? { repliedByDoctor: ratingFilterSelectedOption }
            : {}),
        })
      );
    }
  }, [ratingFilterSelectedOption]);

  useEffect(() => {
    if (isSearchReviewOn && debouncedDoctorSearch.length === 0) {
      setIsSearchReviewOn(!isSearchReviewOn);
      dispatch(fetchDoctorReviewRatingList({}));
      return;
    }
    if (debouncedDoctorSearch) {
      dispatch(
        fetchDoctorReviewRatingList({
          keyword: searchReview,
        })
      );
    }
  }, [debouncedDoctorSearch]);

  if (doctorProfileInfo.isLoading) {
    return <Loader />;
  }

  return (
    <Grid item className={style.container}>
      <Grid item className={style.doctorProfileHeaderContainer}>
        <Grid item className={style.doctorImageContainer}>
          <img
            src={doctorProfile?.photo_url}
            alt="doctorPhoto"
            width={120}
            height={120}
          />
        </Grid>
        <Grid item className={style.doctorProfileDetailContainer}>
          <Typography component={"h4"}>{doctorProfile?.name}</Typography>
          <Typography component={"h5"}>{doctorProfile?.speciality}</Typography>
          <Typography component={"h6"}>
            {" "}
            {doctorProfile?.qualification_details &&
              doctorDegreeName(doctorProfile?.qualification_details)}
          </Typography>
          <Typography component={"p"} className={style.doctorExp}>
            {doctorProfile?.experience_years} Years Exp
          </Typography>
          <Typography
            component={"p"}
            className={style.doctorProfileView}
            onClick={viewDoctorProfileDetail}
          >
            View Profile
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={style.reviewFilterContainer}>
        <Grid item className={style.reviewRatingSearchContainer}>
          {!isSearchReviewOn && (
            <Grid item className={style.reviewRatingCountContainer}>
              <Typography component={"p"} className={style.ratingCount}>
                <GetImages name="YellowStarSmallIcon" width="20" height="16" />
                {doctorProfile?.averageRating}{" "}
                <Typography component={"span"}>{"•"}</Typography>{" "}
              </Typography>
              <Typography component={"p"} className={style.reviewCount}>
                {doctorProfile?.reviews} Reviews
              </Typography>
            </Grid>
          )}
          {isSearchReviewOn && (
            <Grid item className={style.searchReviewInput}>
              <input
                type="text"
                value={searchReview}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchReview(event.target.value)
                }
                placeholder="Search review"
                autoFocus
              />
            </Grid>
          )}
          <Grid
            className={style.searchReviewIcon}
            item
            onClick={() => setIsSearchReviewOn(!isSearchReviewOn)}
          >
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Grid>
        </Grid>
        <Grid item className={style.ratingFilterContainer}>
          <ChipSelect
            data={ratingFilterOptions}
            selectedValue={ratingFilterSelectedOption}
            setSelectedValue={setRatingFilterSelectedOption}
            variant={IChipVariant.ROUNDED}
            showCarousel={true}
            showClearBtn={true}
          />
        </Grid>
      </Grid>
      <Grid item className={style.patientReviewsContainer}>
        <DoctorReviews />
      </Grid>
    </Grid>
  );
};

export default Profile;
