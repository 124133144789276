import { GBSCError } from "../../../models/GBSCError";

import {
  IAccessToken,
  IMobileVerifyOrSendOTPReq,
  IMobileVerifyOrSendOTPRes,
  IOTPVerifyReq,
  IOTPVerifyRes,
} from "../../../models/onboarding";

import { get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const mobileVerifyOrSendOTPApi = async (
  requestData: IMobileVerifyOrSendOTPReq
): Promise<IMobileVerifyOrSendOTPRes | GBSCError> => {
  try {
    const response = await post<IMobileVerifyOrSendOTPRes>(
      APIEndpoint.onBoarding.login,
      requestData
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }

    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const OTPVerifyApi = async (
  requestData: IOTPVerifyReq
): Promise<IOTPVerifyRes | GBSCError> => {
  try {
    const response = await post<IOTPVerifyRes>(
      APIEndpoint.onBoarding.otpVerify,
      requestData
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getAccessTokenApi = async (): Promise<
  IAccessToken | GBSCError
> => {
  try {
    const response = await get<IAccessToken>(APIEndpoint.accessToken);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
