import React from "react";
import AliceCarousel from "react-alice-carousel";

import "./Carousel.css";
import { Grid } from "@mui/material";

interface IProps {
  items: React.ReactNode | any;
  isDotsShow?: boolean;
  itemPerPage?: number;
  autoWidth?: boolean;
}

const Carousel: React.FC<IProps> = (props) => {
  const {
    items,
    isDotsShow = true,
    itemPerPage = 1,
    autoWidth = false,
  } = props;

  const responsive = {
    0: { items: itemPerPage },
    568: { items: itemPerPage },
    1024: { items: itemPerPage },
  };

  return (
    <Grid item className={"aliceCarouselGap"}>
      <AliceCarousel
        touchTracking
        items={items}
        responsive={responsive}
        disableDotsControls={!isDotsShow}
        disableButtonsControls
        autoWidth={autoWidth}
      />
    </Grid>
  );
};

export default Carousel;
